<div class="mt-md-3 container-fluid">
    <div class="row ">

        <div class=" col-12 col-md-8 offset-md-2">
            <div class="row rmt-2" *ngIf="!loading_person">
                <div class="col-12  col-md-8 offset-md-2">

                    <h5 class="mb-1 text-secondary mb-4 text-center">

                        <i class="  fas fa-user"></i> Penalidades

                    </h5>

                    <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && !person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                        <img *ngIf="person?.person.gender != 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatar.png')">
                        <img *ngIf="person?.person.gender == 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatara-female.png')">
                    </div>


                    <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                        <img class="rounded-circle  " [src]="sanitize(person?.person?.file_selfie?.url)">
                    </div>

                    <div *ngIf="!loading_person && person?.person" class="text-center mb-3">
                        <div class="text-capitalize h4 text-dark">{{person?.person?.name}} {{person?.person?.lastName}}</div>
                        <div> {{getIdentity(person)}}</div>
                        <div> {{getContact(person)}}</div>
                    </div>

                </div>
            </div>

            <ng-container *ngIf="!loading && person?.person" class="mt-2">
                <div class="row mt-2" >
                    <div class="col-12  col-md-8 offset-md-2">
                        <ng-container *ngFor="let row of resp_paymentsCredits">
                            <div class="mt-5 my-3 p-3 bg-body rounded shadow-sm" >
                                <div class="row  row-k">

                           
                                            <div class="col-12">
                                                <div class=" text-secondary">Monto total</div>
                                                <h5 class="fw-bolder text-dark"> <ng-container >{{row?.amount | currency:'USD':'': '1.2-2'}} USD</ng-container></h5>
                                            </div>

                                            <div class="col-12 " *ngIf="row?.totalpaymentReference  > 0 && row?.payment_status?.code == 'PaymentPending'">
                                                <div class="small text-secondary">Total (Por conciliar)
                                                </div>
                                                <div class=" text-dark"> {{row?.totalpaymentReference | currency:'USD':'': '1.2-2'}} USD</div>
                                            </div>
                                            
                                            <div class="col-6 ">
                                                <div class=" text-secondary">Penalidad</div>
                                                <div class=" text-dark"> {{row?.fee?.concept_deduction?.name}}<br>                                                            
                                                <div class="text-secondary small " >  {{row?.fee?.code }}</div>
                                            </div>
                                            </div>
                                            <div class="col-6 text-end">
                                                <div class=" text-secondary">Estatus </div>
                                               
                                                    <div [ngClass]="{
                                                    'text-success': row?.payment_status?.code == 'PaymentComplete',
                                                    'text-dark': row?.payment_status?.code == 'PaymentTrash',
                                                    'text-warning': row?.payment_status?.code == 'PaymentPending'
                                                }"  [title]="row?.payment_status?.description">{{row?.payment_status?.name}} </div>
            
                                                
                                            </div>

                                            

                                           

                                            <div class="col-6">
                                                <div class=" text-secondary">Crédito</div>
                                                <div class=" text-dark"> {{row?.installment?.invoice?.identifier}}</div>
                                            </div>

                                            <div class="col-6 text-end">
                                                <div class=" text-secondary">Cuota</div>
                                                <div class=" text-dark"> {{row?.installment?.order}}</div>
                                            </div>

                                            <div class="col-6">
                                                <div class=" text-secondary">Fecha de pago programada</div>
                                                <div class=" text-dark"> {{ row?.installment?.scheduledPaymentDate | date: 'dd/MM/yyyy' }}</div>
                                            </div>

                                            <div class="col-6 text-end" *ngIf="row?.fee?.attribute==='LATE_PAYMENT_DAYS'">
                                                <div class=" text-secondary" >Dias de morosidad</div>
                                                <div class=" text-dark"> {{ row?.days }}</div>
                                            </div>

                                            <div class="col-6 text-end" *ngIf="row?.fee?.attribute==='ADDITIONAL_DAYS'">
                                                <div class=" text-secondary" >Dias de extensión</div>
                                                <div class=" text-dark"> {{ row?.days }}</div>
                                            </div>
        
                                            
                                       
        
                                    



                                   


                                        <div class="fw-bold text-secondary">Pagos</div>
                                        <!-- Conciliados -->
                                        <ng-container class="mt-3" *ngFor="let payment of row.payments">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class=" small">{{ payment?.payment?.paymentMethodId }}</div>
                                                        <div class="text-primary small cursor-pointer" (click)="openBottom(paymentcontainer,{ person_code: row.customer.code, code_payment: payment?.payment?.code})">  {{payment?.payment?.code}}</div>
                                                    </div>
                                                    <div class="col-6  text-end ">
                                                        <div class=" text-dark"> {{ payment?.amount  | currency:'USD':'': '1.2-2'}} USD</div>

                                                        <div class="text-success" *ngIf="payment?.payment?.reconciled_payment"><span class="badge text-bg-success">Aprobado</span> </div>
                                                        <div *ngIf="!payment?.payment?.reconciled_payment"><span  class="badge text-bg-warning">Por conciliar</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <!-- Sin conciliar -->
                                        <ng-container *ngIf="row?.payment_status?.code == 'PaymentPending'">
                                            <ng-container class="mt-3" *ngFor="let payment of row.paymentsFeeReferences">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div class=" small">{{ payment?.payment?.paymentMethodId }}</div>
                                                            <div class="text-primary small cursor-pointer" (click)="openBottom(paymentcontainer,{ person_code: row.customer.code, code_payment: payment?.payment?.code})">  {{payment?.payment?.code}}</div>
                                                        </div>
                                                        <div class="col-6  text-end ">
                                                            <div class=" text-dark"> {{ payment?.payment?.amount  | currency:'USD':'': '1.2-2'}} USD</div>

                                                            <div class="text-success" *ngIf="payment?.payment?.reconciled_payment"><span class="badge text-bg-success">Aprobado</span> </div>
                                                            <div *ngIf="!payment?.payment?.reconciled_payment && row?.payment_status?.code == 'PaymentPending'"><span  class="badge text-bg-warning">Por conciliar</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </ng-container>


                                        <div >

                                            <div *ngIf=" (( row?.due_balance) > 0) &&  row?.payment_status?.code != 'PaymentInactivo' " class="col-12 text-end mt-3">
                                              <span class="badge text-bg-warning">{{ (row?.due_balance) | currency:'USD':'': '1.2-2'}} USD faltantes</span> 
                                            </div>
            
                                        </div>

                                       

                                        
        
                                









                                </div>
                            </div>
                        </ng-container>
                    </div>

                </div>


            </ng-container>


            <div *ngIf="!loading && !loading_person &&  person?.person && resp_paymentsCredits?.length < 1" class="col-12  col-md-8 offset-md-2">
                <div class="mt-5 my-3 p-3 bg-body rounded shadow-sm">
                    <div>No se encontraron pagos registrados</div>
                </div>
            </div>


            <div *ngIf="!loading_person && !person?.person">
                <div>No se encontro person con este id: {{queryParams?.code}}</div>
            </div>


            <ng-container *ngIf="loading_person ||  loading ">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>
        </div>
    </div>

</div>


<ng-template #paymentcontainer let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                               Detalle de Pago
                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click'); "></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class=" container">
            <div class="row">
                <div class=" col-12 col-md-10 offset-md-1">
                    <div class="text-start">
                        <app-person-payments [code_person]="params_payment['person_code']" [code_payment]="params_payment['code_payment']" (resetStatus)="response($event)"></app-person-payments>
  
                    </div>
                </div>
            </div>
        </div>
    </div>
  </ng-template>


  <ng-template #fee let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                               Detalle de Fee
                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click'); "></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class=" container">
            <div class="row">
                <div class=" col-12 col-md-10 offset-md-1">
                    <div class="text-start">
                        <app-fee-detail [code_fee]="params_payment['code_fee']"  ></app-fee-detail>
  
                    </div>
                </div>
            </div>
        </div>
    </div>
  </ng-template>