import { DatePipe } from '@angular/common';
import { noUndefined } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { async } from '@angular/core/testing';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import { mobiscroll, MbscDatetimeOptions, MbscRangeOptions } from '@mobiscroll/angular';


mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light',


};

const now = new Date();
//const week = [ new Date(now.getFullYear(),0, 1),now];
const week = [ now,now];


@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.css'],
  providers: [DatePipe]
})



export class ExportComponent implements OnInit {

  range: Array < Date > = week;
  date: Array < Date > = [];
  nonForm: Array < Date > = [];
  external = week;
  d1:any;
  d2:any;
  status_approved:any = 'ALL';

  trace_documets:any  =[];

  dateSetting: MbscDatetimeOptions = {
    lang: 'es',
    theme: 'ios',
    themeVariant: 'light',
    responsive: {
      small: {
          controls: ['calendar'],
          display: 'center'
      },
      medium: {
          display: 'bubble',
      }
  }

  };

  birthday = new Date();
  filterData:any = {f:new Date(),status:''}
  queryParams: any;
  person: any;
  section_transactions: any = [];
  transaction: any = {};
  section_transaction: any;
  loading_person = false;
  loading = false;

  buro_response: any;
  buro: any;
  buro_score: any;
  activatePsico: any;
  showActivatePsico: any;

  loading_buro = false;
  psicometrico: any;
  psicometrico_response: any;
  psicometrico_array: any = [];
   type_approved: any;
   clientQuota:any;
   invoiceCredits: any = [];
   resp_invoiceCredits: any = [];
   installments: any = [];
   installmentNexPayment :any;
    view = 1;
    invoiceSelected:any;
    installMentSelected:any;
    payment:any;
    stadistic:any;



  date_base: any = new Date;
  date_base_string :any;

  mobileSettings: MbscDatetimeOptions = {
    display: 'bottom',
    themeVariant: 'dark',
};


desktopSettings: MbscDatetimeOptions = {
    display: 'bubble',
    touchUi: false
};

loadingXls:any;
loadingXls2:any;
type:any = 2;
env:any = environment;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService


  ) { }

  ngOnInit(): void {
    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');


    this.date_base_string = this.datePipe.transform(this.date_base, 'MM/dd/yyyy');

    this.loading_person = true;
    this.setNewType();
  }


  getStadisticServer = async () => {
    try{
        return this._user.getstadistic_invice_status();

      } catch (error) {
        console.error(error);


      }
  }


  getListInvoiceCredits = async () => {
    try {

      const resp_invoiceCredits = await this.listInvoiceCredits();

      this.resp_invoiceCredits = resp_invoiceCredits?.res;

      const resp = await this.getStadisticServer();
      this.stadistic = resp?.res;


    } catch (error) {
      console.error(error);

    }


    this.loading_person = false;


  }


  listInvoiceCredits = async () => {
    try{


        return this._user.listInvoiceCredits(this.filterData);

      } catch (error) {
        console.error(error);


      }
  }



  getPersonInstallmentsInvoiceCredits = async (invoice:any) => {
    this.loading = true;

    this.invoiceSelected = invoice;
    try {
         const data =  {
          code_invoice:invoice?.code

         }
       const  installments  = await this._user.getPersonInstallmentsInvoiceCredits(data);

       this.installments = installments?.res;

       this.installments.map((value:any, index:any) => {
        if(value?.installMent?.payment_status?.code != 'PaymentComplete' && !this.installmentNexPayment){

          this.installmentNexPayment = value?.installMent?.code;

        }

        value["totalpaymentReference"] = 0;

        value?.paymentReference?.forEach((value2:any) =>{
          value["totalpaymentReference"] =  value["totalpaymentReference"]  +  value2?.payment?.amount;
        });



        return value;
      });



/*
      this.installments.sort((a:any, b:any) => {
        let temp = a?.installMent?.order;
        let temp2 = b?.installMent?.order;

        return  temp - temp2;
      });*/


       this.view = 2;

      } catch (error) {
        console.error(error);

      }

      this.loading = false;

  }


  getPersonInstallmentpayment = async (installMentSelected:any) => {

    this.loading = true;

    this.installMentSelected = installMentSelected;
    try {
         const data =  {
          code_installMent:installMentSelected?.code

         }

       const  payment  = await this._user.getPersonInstallmentpayment(data);

       this.payment = payment?.res;
       this.view = 3;

      } catch (error) {
        console.error(error);


      }

      this.loading = false;

  }


  getIdentity(item: any) {

    if (!(item?.identitys?.length > 0))
      return "";

    return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;

  }


  getContact(item: any) {

    if (!(item?.constacts?.length > 0))
      return "";

    return `${item?.constacts[0]?.contact}`;


  }


  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }



  public redirect = () => {

    let Doc_ID;
    let Type_ID;
    let TLF;


    if (this.person?.identitys?.length > 0) {

      const data = this.person?.identitys[0];
      Type_ID = data.type_identity?.additionalType;
      Doc_ID = data.identity;

    }


    const data = {

      CED: environment.CED,
      IP: environment.IP_LOCAL,
      Type_ID,
      Doc_ID,
      PN: this.person?.person?.name,
      PA: this.person?.person?.lastName,
      FN: this.datePipe.transform(this.person?.person?.birth_date, 'yyyy-MM-dd'),
      GN: this.person?.person?.gender,
      TLF: "",
      EMAIL: ""

    }

    this.redirecFormService.postRedirect(environment.externalPsicometrico, data);

  }

  closeInstallment(){

    if(this.view == 2 ){
      this.installments = [];
      this.view = 1;
      this.invoiceSelected=null;
      this.installmentNexPayment = null;

    }else  if(this.view == 3 ){
      this.view = 2;
      this.installMentSelected = null;
      this.payment = null;
      this.installmentNexPayment = null;

    }

         }



redirectTo(code:any) {
  this.router.navigate([`/console/payments/person/${code}`], {});
}

 public generate_csv   =  async () =>{

  this.loadingXls = true;
  try {
  const  array_name  = await this._user.getInstallMentsFN({f:this.date_base_string});
  this.loadingXls = false;

  debugger

  array_name?.res?.map((value:any)=>{

    let url = `${environment.BASE_LA}/csv/${value?.ruta}`;
    window.open(`${url}`);

  })

  } catch (error) {

    this.loadingXls = false;


  }

}


public generate_installment_csv   =  async () =>{

  this.loadingXls2 = true;
  try {

    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');


   await this._user.getInstallMents_statusFN(
    {
      init:this.d1,
      end:this.d2
    });
  this.loadingXls2 = false;



   let url = `${environment.BASE_LA}/csv/installments_status.xlsx`;
   window.open(`${url}`);

  } catch (error) {

    this.loadingXls2 = false;


  }

}



dateSet(e: any) {

  this.resetFilter();

  this.filterData.f = this.date_base;


  let date_base =  e.valueText;

  let  temp = date_base? date_base.split("/") : [];
  this.date_base_string = `${temp[1]}/${temp[0]}/${temp[2]}`;



}

filter = async () => {

  const resp_invoiceCredits = await this.listInvoiceCredits();
  this.resp_invoiceCredits = resp_invoiceCredits?.res;

}


  // simple handler without any parameters
  birthdaySet() {


  this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
  this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');

  debugger

    }

resetFilter(){

  this.filterData = {f:new Date(),status:''};

}

nonFormSettings: MbscRangeOptions = {
  showSelector: false
};

externalSettings: MbscRangeOptions = {
  showOnTap: false,
  showOnFocus: false,
  showSelector: false
};



public generate_invoices_csv   =  async () =>{

  this.loadingXls = true;
  try {

    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');


    try {

      let result = await this._user.getAllInvoicesReportGet(
        {
          init:this.d1,
          end:this.d2
        });


        this.getTrace_documets('INVOICES_1');


    } catch (error) {
      console.log(error);


    }


  this.loadingXls = false;



   //let url = `${environment.BASE_LA}/${result}`;
 //  window.open(`${url}`);

  } catch (error) {

    this.loadingXls2 = false;


  }

}


public generate_payments_csv   =  async (type:any = '') =>{

  this.loadingXls = true;
  try {

    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');


    try {

      let result = await this._user.allPaymentsReportGet(
        {
          init:this.d1,
          end:this.d2,
          type
        });

        if(type == 'U'){
          this.getTrace_documets('PAYMETS_S3');

        }else{
          this.getTrace_documets('PAYMETS_S2');

        }


    } catch (error) {
      console.log(error);


    }


  this.loadingXls = false;



   //let url = `${environment.BASE_LA}/${result}`;
 //  window.open(`${url}`);

  } catch (error) {

    this.loadingXls2 = false;


  }

}

public generate_payments_fee_csv   =  async (type:any = '') =>{

  this.loadingXls = true;
  try {

    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');


    try {

      let result = await this._user.allFeesPaymentsReportGet(
        {
          init:this.d1,
          end:this.d2,
          type
        });

        if(type == 'U'){
          this.getTrace_documets('FEES_PAYMENTS_REPORT');

        }else{
          this.getTrace_documets('FEES_PAYMENTS_REPORT');

        }


    } catch (error) {
      console.log(error);


    }


  this.loadingXls = false;



   //let url = `${environment.BASE_LA}/${result}`;
 //  window.open(`${url}`);

  } catch (error) {

    this.loadingXls2 = false;


  }

}


public generate_payments_fee_v2_csv   =  async (type:any = '') =>{

  this.loadingXls = true;
  try {

    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');
  

    try {

      let result = await this._user.allFeesPaymentsReportV2Get(
        {
          init:this.d1,
          end:this.d2,
          type
        });
    
        if(type == 'U'){
          this.getTrace_documets('FEES_PAYMENTS_REPORT_V2');

        }else{
          this.getTrace_documets('FEES_PAYMENTS_REPORT_V2');

        }

      
    } catch (error) {
      console.log(error);
      
      
    }
    
 
  this.loadingXls = false;

  

   //let url = `${environment.BASE_LA}/${result}`;
 //  window.open(`${url}`);

  } catch (error) {
    
    this.loadingXls2 = false;


  }

}


public generate_clients_csv   =  async () =>{

  this.loadingXls = true;
  try {

    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');

    try {


      let result = await this._user.allclientsReportGet(
        {
          init:this.d1,
          end:this.d2,
        });

          this.getTrace_documets('CLIENT_1');


    } catch (error) {
      console.log(error);


    }


  this.loadingXls = false;


   //let url = `${environment.BASE_LA}/${result}`;
 //  window.open(`${url}`);

  } catch (error) {

    this.loadingXls2 = false;


  }

}


public generate_clients_domic_csv   =  async () =>{

  this.loadingXls = true;
  try {

    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');

    try {

  //accounts_person_report
      let result = await this._user.clientsdomicReportGet(
        {
          init:this.d1,
          end:this.d2,
          approved_status: this.status_approved

        });


          this.getTrace_documets('CLIENT_2');

        debugger

    } catch (error) {
      console.log(error);


    }


  this.loadingXls = false;


   //let url = `${environment.BASE_LA}/${result}`;
 //  window.open(`${url}`);

  } catch (error) {

    this.loadingXls2 = false;


  }

}


public generate_dist_payments_csv   =  async () =>{

  this.loadingXls = true;
  try {

    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');

    try {

  //accounts_person_report
      let result = await this._user.dist_paymentsReportGet(
        {
          init:this.d1,
          end:this.d2
        });


          this.getTrace_documets('DIST_PAYMENTS');

        debugger

    } catch (error) {
      console.log(error);


    }


  this.loadingXls = false;


   //let url = `${environment.BASE_LA}/${result}`;
 //  window.open(`${url}`);

  } catch (error) {

    this.loadingXls2 = false;


  }

}


public generate_cliente_stadistic_csv   =  async () =>{

  this.loadingXls = true;
  try {

    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');

    try {

  //accounts_person_report
      let result = await this._user.clientEstadisticReportGet(
        {
          //init:this.d1,
        //  end:this.d2
        });


          this.getTrace_documets('CLIENT_STADITIC');

        debugger

    } catch (error) {
      console.log(error);


    }


  this.loadingXls = false;


   //let url = `${environment.BASE_LA}/${result}`;
 //  window.open(`${url}`);

  } catch (error) {

    this.loadingXls2 = false;


  }

}

public generate_sap_payments_csv =  async () =>{

  this.loadingXls = true;
  try {

    let d1 = this.datePipe.transform(this.date_base, 'MM/dd/yyyy');

    try {

  //accounts_person_report
      let result = await this._user.sapPaymentsReportGet(
        {
          date:this.date_base

        });


          this.getTrace_documets('SAP_P');

        debugger

    } catch (error) {
      console.log(error);


    }


  this.loadingXls = false;


   //let url = `${environment.BASE_LA}/${result}`;
 //  window.open(`${url}`);

  } catch (error) {

    this.loadingXls2 = false;


  }

}

generate_sap_giros_csv =  async () =>{

  this.loadingXls = true;
  try {

    let d1 = this.datePipe.transform(this.date_base, 'MM/dd/yyyy');

    try {

  //accounts_person_report
      let result = await this._user.sapGirosReportGet(
        {
          date:this.date_base

        });


          this.getTrace_documets('SAP_G');

        debugger

    } catch (error) {
      console.log(error);


    }


  this.loadingXls = false;


   //let url = `${environment.BASE_LA}/${result}`;
 //  window.open(`${url}`);

  } catch (error) {

    this.loadingXls2 = false;


  }

}

generate(){


  if(this.type == 1){

    this.generate_csv();

  }if(this.type == 2){

    this.generate_installments2_csv();

  }if(this.type == 3){

    this.generate_invoices_csv();

  }

  if(this.type == 4){

    this.generate_payments_csv();

  }
  if(this.type == 5){

    this.generate_payments_csv('U');

  }
  if(this.type == 6){

    this.generate_clients_csv();

  }

  if(this.type == 7){

    this.generate_clients_domic_csv();

  }

  if(this.type == 8){

    this.generate_sap_payments_csv();

  }

  if(this.type == 9){

    this.generate_sap_giros_csv();

  }


  if(this.type == 15){

    debugger
    this.generate_dist_payments_csv();

  }


  if(this.type == 17){

    debugger
    this.generate_cliente_stadistic_csv();

  }

  if(this.type == 18){


    this.generate_payments_fee_csv();

  }

  if(this.type == 19){

    
    this.generate_payments_fee_v2_csv();
    
  }



}


setNewType(){
  this.trace_documets = [];

  if(this.type == 3){

    this.getTrace_documets('INVOICES_1');
  }else  if(this.type == 2){

    this.getTrace_documets('INSTALLMENT_1');

  }else  if(this.type == 4){

    this.getTrace_documets('PAYMETS_S2');

  }else  if(this.type == 5){

    this.getTrace_documets('PAYMETS_S3');


  }else  if(this.type == 6){

    this.getTrace_documets('CLIENT_1');
  }
  else  if(this.type ==7){

    this.getTrace_documets('CLIENT_2');
  }
  else  if(this.type ==8){

    this.getTrace_documets('SAP_P');
  }
  else  if(this.type ==9){

    this.getTrace_documets('SAP_G');
  }

  else  if(this.type ==15){

    this.getTrace_documets('DIST_PAYMENTS');
  }


  else  if(this.type ==17){

    this.getTrace_documets('CLIENT_STADITIC');
  }

  else  if(this.type ==18){

    this.getTrace_documets('FEES_PAYMENTS_REPORT');
  }else  if(this.type ==19){

    this.getTrace_documets('FEES_PAYMENTS_REPORT_V2');
  }



    let d1 = this.datePipe.transform(this.date_base, 'MM/dd/yyyy');




}



public getTrace_documets   =  async (type:any) =>{

  try {
    try {

      let result = await this._user.traceDocumentsGet({type});

      this.trace_documets = result.resp;

    } catch (error) {
      console.log(error);


    }

  } catch (error) {

    this.loadingXls2 = false;


  }

}

dowloader_document(url:any){


  let url_f = `${environment.BASE_LA}/${url}`;
   window.open(`${url_f}`);


}

 addMinutes(date:any, minutes:any) {
  return new Date(date.getTime() + minutes*60000);
}



public generate_installments2_csv   =  async () =>{

  debugger
  this.loadingXls = true;

  try {

    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');


    try {


      await this._user.getInstallMents_statusFN(
        {
          init:this.d1,
          end:this.d2
        });

        this.getTrace_documets('INSTALLMENT_1');


    } catch (error) {
      console.log(error);


    }


  this.loadingXls = false;



   //let url = `${environment.BASE_LA}/${result}`;
 //  window.open(`${url}`);

  } catch (error) {

    this.loadingXls2 = false;


  }

}

}
