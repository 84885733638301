import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Plan } from '../../planModel';
import { PlanService } from '../../plan.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-new-plan-v2',
  templateUrl: './new-plan-v2.component.html',
  styleUrls: ['./new-plan-v2.component.css'],
})
export class NewPlanV2Component implements OnInit, OnDestroy {
  @Output() close = new EventEmitter<any>();
  plan: any;
  isLoading = false;
  filterType: any;
  typeInvoice: any;
  shopList = '';
  filters: any;
  terms: any;
  isActive = false;
  accordioncontroller = [true, true, true, true, true, true, true];
  shops: any = [];
  shopsForPayload: any = [];
  dynamicCodeList: any = [];
  codeList: any = [];
  auxList: any = [];
  auxList2: any = [];
  filterText: string = '';
  filterText2: string = '';
  showListStores = false;
  showListCodes = false;
  //formTerms = false;
  btnTitle = 'Agregar Plan';
  errorControls: any = {
    errorCode: false,
    errorInitDate: false,
    errorEndDate: false,
    errorDescription: false,
    errorVisualization: false,
    errorQuotaMin: false,
    errorQuotaMax: false,
    errorInitialType: false,
    errorInitialAmount: false,
    errorMinAmountBase: false,
    errorMaxAmountBase: false,
    errorMinAmountToPay: false,
    errorPaymentTermsInstallment: false,
    errorfrequencyType: false,
    errorInstallmentDays: false,
    errorFilterType: false,
    errorAudienceType: false,
    errorShopType: false,
    errorShopList: false,
    errorCodeList: false,
    errorPriority: false,
    //errorFormTerms: false,
  };
  canEdit = true;
  globalError = false;
  configHistoryChange = false;
  electronicBill = true;
  weekendAmounts = true;
  today = today.toISOString().split('T')[0];
  constructor(private _planService: PlanService, private _user: UserService) {
    let examplePlan = DEFAULT_PLAN_DATA;
    sessionStorage.setItem('DEFAULT_PLAN_DATA', JSON.stringify(DEFAULT_PLAN_DATA))
    sessionStorage.setItem('defaultFilter', JSON.stringify(defaultFilter))
    sessionStorage.setItem('defaultTerms', JSON.stringify(defaultTerms))
    let exampleFilter = defaultFilter;
    let exampleTerms = defaultTerms;
    this.plan = examplePlan;
    this.filters = exampleFilter;
    this.terms = exampleTerms;
  }

  ngOnInit(): void {
    this.getshops();
    this.getCodes();
    if (this._planService.selectedPlan) {
      this.setPlanInfo();
    } else {
      this.cleanFormData(true);
    }
  }

  ngOnDestroy() {
    this.cleanFormData();
  }

  setPlanInfo() {
    this.terms.installments = '';
    this.plan = this._planService.selectedPlan;
    this.plan.payment_terms.forEach((term: any, index: number) => {
      if (index < this.plan.payment_terms.length - 1) {
        this.terms.installments =
          this.terms.installments + term.installments + ', ';
      } else {
        this.terms.installments = this.terms.installments + term.installments;
      }
    });

    this.terms.frequency.type = this.plan.payment_terms[0].frequency.type;
    this.validateVersions();
    this._planService.selectedPlan = null;
    console.log(this.plan);
    this.canEdit = false;
    this.btnTitle = 'Actualizar Plan';
    if (this.plan.visibility == 'PRIVATE') {
      this.plan.visibility = '2';
    } else {
      this.plan.visibility = '1';
    }

    if (this.plan.initial.type == 'percentage') {
      this.plan.initial.type = '1';
    } else {
      this.plan.initial.type = '2';
    }

    if (this.plan.params.init_date) {
      this.plan.params.init_date = `${this.plan.params.init_date
        .toString()
        .substring(0, 10)}`;
    }

    if (this.plan.params?.end_date) {
      this.plan.params.end_date = `${this.plan.params.end_date
        .toString()
        .substring(0, 10)}`;
    }

    if (this.plan.params.filters.general.invoices) {
      this.filterType = '2';
      if (this.plan.params.filters.general.invoices.shop.type == 'white_list') {
        this.filters.general.invoices.shop.type = '1';
      } else {
        this.filters.general.invoices.shop.type = '2';
      }
    } else {
      this.filterType = '1';
      this.filters.general.person.audience =
        this.plan.params.filters.general.person.audience;
    }

    if (this.plan.params?.filters?.dinamic_code?.person?.type == 'white_list') {
      this.filters.dinamic_code.person.type = '1';
    } else if (
      this.plan.params?.filters?.dinamic_code?.person?.type == 'black_list'
    ) {
      this.filters.dinamic_code.person.type = '2';
    }

    if (!this.plan.params?.filters?.dinamic_code?.person?.type) {
      this.plan.params.filters.dinamic_code = defaultFilter.dinamic_code
    }
  }

  validateVersions() {
    if (this.plan.version_change.length > 0) {
      this.plan.version_change.forEach((item: any) => {
        Object.defineProperty(item, 'showSubItems', {
          value: false,
          writable: true,
        });
      });

      this.plan.version_change = this.plan.version_change.reverse();
    }
  }

  showHistorydetails(item: any) {
    this.plan.version_change.forEach((element: any) => {
      if (element.version.internal_code == item.version.internal_code) {
        element.showSubItems = !element.showSubItems;
      }
    });
  }

  evaluateFilterType(event: any) {}
  resetErrorControllers() {
    this.errorControls = {
      errorName: false,
      errorCode: false,
      errorInitDate: false,
      errorEndDate: false,
      errorDescription: false,
      errorVisualization: false,
      errorQuotaMin: false,
      errorQuotaMax: false,
      errorInitialType: false,
      errorInitialAmount: false,
      errorMinAmountBase: false,
      errorMaxAmountBase: false,
      errorMinAmountToPay: false,
      errorPaymentTermsInstallment: false,
      errorfrequencyType: false,
      errorInstallmentDays: false,
      errorFilterType: false,
      errorAudienceType: false,
      errorShopType: false,
      errorShopList: false,
      errorPriority: false,
      //errorFormTerms: false,
    };
    this.globalError = false;
  }

  validateFields() {
    debugger;
    let errorFinded = false;
    this.resetErrorControllers();
    if (!this.plan.name) {
      this.errorControls.errorName = true;
      errorFinded = true;
    }

    if (!this.plan.code) {
      this.errorControls.errorCode = true;
      errorFinded = true;
    }

    if (!this.plan.params.init_date) {
      this.errorControls.errorInitDate = true;
      errorFinded = true;
    }

    // if (!this.plan.description) {
    //   this.errorControls.errorDescription = true;
    //   errorFinded = true
    // }

    if (!this.plan.description) {
      this.errorControls.errorDescription = true;
      errorFinded = true;
    }

    if (!this.plan.visibility) {
      this.errorControls.errorVisualization = true;
      errorFinded = true;
    }

    if (!this.plan.params.current_quota_min) {
      this.errorControls.errorQuotaMin = true;
      errorFinded = true;
    }

    // if (!this.plan.params.current_quota_max) {
    //   this.errorControls.errorQuotaMax = true;
    //   errorFinded = true;
    // }

    if (!this.plan.params?.current_quota_max) {
      this.errorControls.errorQuotaMax = true;
      errorFinded = true;
    }

    if (!this.plan.initial?.type && this.plan.initial?.status) {
      this.errorControls.errorInitialType = true;
      errorFinded = true;
    }

    if (!this.plan.initial?.amount && this.plan.initial?.status) {
      this.errorControls.errorInitialAmount = true;
      errorFinded = true;
    }

    if (!this.plan.params.min_amount_base) {
      this.errorControls.errorMinAmountBase = true;
      errorFinded = true;
    }

    if (!this.plan.params.max_amount_base) {
      this.errorControls.errorMaxAmountBase = true;
      errorFinded = true;
    }

    if (Number(this.plan.params.max_amount_base )< Number(this.plan.params.min_amount_base)) {
      this.errorControls.errorMaxAmountBase = true;
      errorFinded = true;
    }

    if (!this.plan.params.min_amount) {
      this.errorControls.errorMinAmountToPay = true;
      errorFinded = true;
    }

    if (!this.terms.installments) {
      this.errorControls.errorPaymentTermsInstallment = true;
      errorFinded = true;
    }

    if (!this.terms.frequency.type) {
      this.errorControls.errorfrequencyType = true;
      errorFinded = true;
    }

    if (
      !this.terms.frequency.number &&
      this.terms.frequency.type == 'dias_continuos'
    ) {
      this.errorControls.errorInstallmentDays = true;
      errorFinded = true;
    }

    // if (!this.filterType) {
    //   this.errorControls.errorFilterType = true;
    //   errorFinded = true
    // }

    // if (this.filterType == 1) {
    //   if (!this.filters.general.person.audience) {
    //     this.errorControls.errorAudienceType = true;
    //     errorFinded = true
    //   }

    // }

    // if (!this.filters.general?.invoices?.shop?.type && this.filterType == 2) {
    //   this.errorControls.errorShopType = true;
    //   errorFinded = true
    // }

    // if (this.shopsForPayload.length == 0 && this.filterType == 2) {
    //   this.errorControls.errorShopList = true;
    //   errorFinded = true
    // }

    // if (this.codeList.length == 0 && this.filters.dinamic_code.person.type) {
    //   this.errorControls.errorShopList = true;
    //   errorFinded = true
    // }

    if (this.plan.params?.end_date < this.plan.params.init_date) {
      delete this.plan.params?.end_date
      this.errorControls.errorEndDate = true;
      errorFinded = true;
    }

    if (
      (this.plan.params?.priority == undefined ||
        this.plan.params?.priority == null) &&
      this.plan.params?.multiple_invoices?.status
    ) {
      this.errorControls.errorPriority = true;
      errorFinded = true;
    }

    // if (!this.formTerms) {
    //   this.errorControls.errorFormTerms = true;
    //   errorFinded = true;
    // }

    if (errorFinded) {
      this.showAllItems();
      return;
    }

    this.bindComplexElements();
  }

  showAllItems() {
    this.globalError = true;
    this.accordioncontroller = [true, true, true, true, true, true, true];
  }

  bindComplexElements() {
    if (this.plan.visibility == '1') {
      this.plan.visibility = 'PUBLIC';
    } else {
      this.plan.visibility = 'PRIVATE';
    }

    if (this.plan.initial.type == '1') {
      this.plan.initial.type = 'percentage';
    } else {
      this.plan.initial.type = 'amount';
    }

    if (this.filters.general?.invoices?.shop?.type == '1') {
      this.filters.general.invoices.shop.type = 'white_list';
    } else {
      this.filters.general.invoices.shop.type = 'black_list';
    }

    if (this.filters.dinamic_code.person.type == '1') {
      this.filters.dinamic_code.person.type = 'white_list';
    } else {
      this.filters.dinamic_code.person.type = 'black_list';
    }

    if (this.shopsForPayload.length > 0) {
      this.filters.general.invoices.shop.list = []
      this.shopsForPayload.forEach((item: any) => {
        if (item.code) {
          this.filters.general.invoices.shop.list.push(item.code);
        }
      });
    }

    if (this.codeList.length > 0) {
      this.filters.dinamic_code.person.list = []
      this.codeList.forEach((item: any) => {
        if (item.code) {
          this.filters.dinamic_code.person.list.push(item.code);
        }
      });
    }

    this.plan.version.name = this.plan.name;
    this.plan.version.description = this.plan.description;

    this.plan.payment_terms = [];
    let termsList = this.terms.installments.toString().split(',');
    termsList.forEach((item: string) => {
      this.plan.payment_terms.push({
        code: this.plan.code + '_' + item,
        frequency:
          this.terms.frequency.type == 'dias_continuos'
            ? this.terms.frequency
            : { type: this.terms.frequency.type },
        name: this.getTermsName(Number(item.trim())), //'cuota pagada en ' + this.terms.frequency.type + ' ' + item,
        order: this.terms.order,
        installments: Number(item.trim()),
      });
    });

    if (this.filterType == 1) {
      let filters = {
        general: {
          person: {
            audience: this.filters.general?.person?.audience || 'NATURAL',
          },
        },
      };
      this.filters.general = filters.general;
    }

    if (this.filterType == 2) {
      let filters = {
        general: {
          invoices: {
            category: this.filters.general.invoices.category,
            shop: {
              type: this.filters.general.invoices.shop.type,
              list: this.filters.general.invoices.shop.list,
            },
          },
        },
      };
      this.filters.general = filters.general;
    }

    this.plan.params.filters = this.filters;


    if (this.plan.params?.filters?.dinamic_code?.person?.list?.length < 1) {
      delete this.plan.params.filters.dinamic_code;
    }

    if (this.plan.params?.filters?.general?.invoices?.shop?.list?.length < 1) {
      delete this.plan.params.filters.general.invoices;
    }

    if(this.plan.params?.end_date == '' ){
      delete this.plan.params.end_date
    };
    this.plan.params.current_quota_min  = parseFloat(this.plan?.params?.current_quota_min);
    this.plan.params.current_quota_max  = parseFloat(this.plan?.params?.current_quota_max);
    this.plan.params.multiple_invoices.count = parseFloat(this.plan?.params?.multiple_invoices?.count);
    this.plan.params.priority = parseFloat(this.plan?.params?.priority);
    this.plan.initial.amount = parseFloat(this.plan?.initial?.amount);
    this.plan.params.min_amount = parseFloat(this.plan?.params?.min_amount);
    this.plan.params.min_amount_base = parseFloat(
      this.plan?.params?.min_amount_base
    );
    this.plan.params.max_amount_base = parseFloat(
      this.plan?.params?.max_amount_base
    );
    if (this.btnTitle == 'Agregar Plan') {
      this.createPlan();
    } else {
      this.updatePlan();
    }
  }

  getTermsName(index: number) {
    //'cuota pagada en ' + this.terms.frequency.type + ' ' + item,
    if (index == 1) {
      return 'Una cuota unica pagada en 1' + this.getType(index);
    } else {
      return index + ' cuotas pagadas ' + this.getType(index);
    }
  }

  getType(index: number) {
    if (index > 1) {
      if (this.terms.frequency.type == 'mes') {
        return 'mensualmente';
      } else if (this.terms.frequency.type == 'semana') {
        return 'semanalmente';
      } else {
        return this.terms.frequency.type;
      }
    } else {
      if (this.terms.frequency.type == 'dias') {
        return 'dia';
      } else {
        return this.terms.frequency.type;
      }
    }
  }

  updateFilters() {
    if (this.shopList) {
      this.filters.general.invoices.shop.list = this.shopList.split(' ');
    }
  }

  updateTerms() {
    this.plan.payment_terms[0] = this.terms;
  }

  validateDates() {
    if (this.plan.params.init_date > this.plan.params?.end_date) {
      this.plan.params.end_date = today.toISOString().split('T')[0];
      console.log(
        'error la fecha de inicio no puede ser mayor que la fecha de finalizacion'
      );
    }
  }

  createPlan() {
    debugger;
    this.isLoading = true;
    this._planService.createPlan(this.plan).subscribe(
      (data: any) => {
        this.isLoading = false;
        console.log(data);
        this.cleanFormData();
      },
      (error) => {
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  updatePlan() {
    this.isLoading = true;
    debugger
    this._planService.updatePlan(this.plan).subscribe(
      (data: any) => {
        this.isLoading = false;
        console.log(data);
        this.cleanFormData();
      },
      (error) => {
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  mangeAccordion(index: number) {
    this.accordioncontroller.forEach((item: boolean, i: number) => {
      if (i == index) {
        this.accordioncontroller[i] = !this.accordioncontroller[i];
      }
    });
  }

  getCodes() {
    this._planService.getCodes().subscribe(
      (data: any) => {
        console.log(data);

        this.dynamicCodeList = data.data;
        if (
          this.plan.params.filters.dinamic_code.person.type == 'black_list' ||
          this.plan.params.filters.dinamic_code.person.type == 'white_list'
        ) {
          const codeList = this.plan.params.filters.dinamic_code.person.list;

          this.dynamicCodeList.forEach((item: any) => {
            codeList.forEach((id: string) => {

              if (item.code == id) {

                //this.showListCodes = true
                this.codeList.push(item);
              }
            });
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  async getshops() {
    try {
      const resp = await this.getshopsServer();
      this.shops = resp?.res;
      if (this.filterType == '2') {
        const codeList = this.plan.params.filters.general.invoices.shop.list;

        this.shops.forEach((item: any) => {
          codeList.forEach((id: string) => {
            if (item.code == id) {

              this.shopsForPayload.push(item);
            }
          });
        });
      }
      let obj_shop: any = {};
      this.shops?.map((item: any) => {
        obj_shop[item.code] = item;
      });
    } catch (error) {
      console.error(error);
    }
  }

  async getshopsServer() {
    try {
      return this._user.getshops({});
    } catch (error) {
      console.error(error);
    }
  }

  filteredItems() {
    if (this.filterText) {
      this.auxList = this.shops.filter((item: any) =>
        item.name.toLowerCase().includes(this.filterText.toLowerCase())
      );
    } else {
      this.auxList = this.shops;
    }
  }

  filteredItems2() {
    if (this.filterText2) {
      this.auxList2 = this.dynamicCodeList.filter((item: any) =>
        item.code.toLowerCase().includes(this.filterText2.toLowerCase())
      );
    } else {
      this.auxList2 = this.dynamicCodeList;
    }
  }

  selectItem(item: any) {
    this.showListStores = false;
    const found = this.shopsForPayload.find(
      (element: any) =>
        element.name.toString().toLowerCase() ==
        item.name.toString().toLowerCase()
    );

    if (!found) {
      this.shopsForPayload.push(item);
    }
  }

  deletItem(item: any) {
    this.shopsForPayload.forEach((elemnt: any, index: number) => {
      if (
        elemnt.name.toString().toLowerCase() ==
        item.name.toString().toLowerCase()
      ) {
        this.shopsForPayload.splice(index, 1);
      }
    });
  }

  selectItem2(item: any) {
    this.showListCodes = false;
    const found = this.codeList.find(
      (element: any) =>
        element.code.toString().toLowerCase() ==
        item.code.toString().toLowerCase()
    );

    if (!found) {
      this.codeList.push(item);
    }
  }

  deletItem2(item: any) {
    this.codeList.forEach((elemnt: any, index: number) => {
      if (
        elemnt.code.toString().toLowerCase() ==
        item.code.toString().toLowerCase()
      ) {
        this.codeList.splice(index, 1);
      }
    });
  }

  showList() {
    this.showListStores = !this.showListStores;
  }

  cleanFormData(isInit = false) {
    let examplePlan = JSON.parse(sessionStorage.getItem('DEFAULT_PLAN_DATA')?? '{}');
    let exampleFilter = JSON.parse(sessionStorage.getItem('defaultFilter')?? '{}');;
    let exampleTerms = JSON.parse(sessionStorage.getItem('defaultTerms')?? '{}');;
    this.plan = examplePlan;
    this.filters = exampleFilter;
    this.terms = exampleTerms;
    this.shops = [];
    this.shopsForPayload = [];
    this.dynamicCodeList = [];
    this.codeList = [];
    this.auxList = [];
    this.auxList2 = [];
    this.filterText = '';
    this.filterText2 = '';
    this.showListStores = false;
    this.showListCodes = false;
    //this.formTerms = false;
    this.resetErrorControllers();
    if (!isInit) {
      this.close.emit(true);
    }
  }

  validateMinAmount() {
    this.errorControls.errorMinAmountToPay = false;
    this.errorControls.errorMinAmountBase = false;

    if (this.plan.params.min_amount) {
      if (this.plan.initial.type == '2') {
         // this.plan.params.min_amount > this.plan.initial.amount
         // this.plan.params.min_amount_base > this.plan.initial.amount
         //plan.params.min_amount > this.plan.initial.amount
         if (Number(this.plan.initial.amount) >= Number(this.plan.params.min_amount)) {
          this.errorControls.errorMinAmountToPay = true;
         }
         if ( Number(this.plan.params.min_amount_base) < Number(this.plan.initial.amount)) {
          this.errorControls.errorMinAmountBase = true;
         }

      } else {

        this.plan.params.min_amount_base =
          this.plan.params.min_amount -
          this.toFixedApp(
            this.plan.params.min_amount *
              this.toFixedApp(this.plan.initial.amount / 100)
          );
      }
    }

    if (this.plan.params.min_amount_base > this.plan.params.max_amount_base) {
      //this.plan.params.max_amount_base = Number(this.plan.params.min_amount) + 10;
    }
  }

  cleanInitialAmount() {
    this.plan.initial.amount = 0;
    this.plan.params.min_amount = 0;
    this.plan.params.min_amount_base  = 0;
    this.plan.params.max_amount_base =0
  }

  validateMaxAmount() {
    this.errorControls.errorMaxAmountBase = false;
    if (Number(this.plan.params.max_amount_base) < Number(this.plan.params.min_amount_base)) {
      this.errorControls.errorMaxAmountBase = true;
    }


  }

  toFixedApp = (amount: any) => {
    let a: any = `e+${2}`;
    let b: any = `e-${2}`;
    return +(Math.round(amount + a) + b);
  };
}
const today = new Date();
const DEFAULT_PLAN_DATA: any = {
  visibility: '',
  date: new Date(),
  code: '',
  name: '',
  description: '',
  state: '',
  status: true,
  initial: {
    status: true,
    type: '',
    amount: 0,
    recalculate: true,
  },
  version: {
    name: '',
    description: '',
  },
  params: {
    min_amount_base: 0,
    max_amount_base: 50,
    current_quota_min: 50,
    current_quota_max: 50,
    min_amount: 0,
    init_date: today.toISOString().split('T')[0],
    client_view: 'true',
    generate_pagares: true,
    generate_pagares_fisico: false,

    weekday: true,
    multiple_invoices: {
      status: true,
      count: 0,
    },
    filters: {
      general: {
        invoices: {
          //  category: '',
          shop: {
            type: '',
            list: [],
          },
        },
        person: {
          audience: 'NATURAL',
        },
      },
      dinamic_code: {
        person: {
          type: '',
          list: [],
        },
      },
    },
    provider: '63dc1737b5e22028eedb051e',
    priority: 0,
  },
  payment_terms: [
    {
      code: '',
      name: '',
      order: 0,
      installments: 1,
      frequency: {
        type: '', // mes, dia, semana, dias continuos
        number: 0, // aca se pasa el numero para saber cada cuantos dias
      },
    },
  ],
  version_change: [],
};

const defaultFilter = {
  general: {
    person: {
      audience: 'NATURAL',
    },
    invoices: {
      shop: {
        type: '',
        list: [],
      },
    },
  },
  dinamic_code: {
    person: {
      type: '',
      list: [],
    },
  },
};

const defaultTerms = {
  code: '',
  name: '',
  order: 0,
  installments: 0,
  frequency: {
    type: '', // mes, dia, semana, dias continuos
    number: 0, // aca se pasa el numero para saber cada cuantos dias
  },
};
